<template>
    <div class="tabs">
        <div class="topSearch">
            上报日期 
            <el-date-picker
                ref="tablePicker"
                v-model="time"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :clearable="false"
                :picker-options="expireTimeOption"
                class="selectBox"
                style="width:360px"
            >
            </el-date-picker>
            数据类型 
            <el-select class="selectBox" size="small" placeholder="请选择" v-model="pageParams.dataType">
                <el-option
                    v-for="item in dataTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
            <el-input placeholder="请输入" size="small" class="selectBox input-with-select" v-model="pageParams.queryWord" style="width:300px">
                <el-select v-model="pageParams.queryType" slot="prepend" placeholder="请选择" style="width:100px">
                    <el-option label="设备名称" value="1"></el-option>
                    <el-option label="所在位置" value="2"></el-option>
                    <el-option label="监测点位" value="3"></el-option>
                    <el-option label="异常信息" value="4"></el-option>
                </el-select>
            </el-input>
            <div class="searchBtn">
                <el-button type="primary" size="small" @click="getList('reset')">查询</el-button>
                <el-button type="info" plain size="small" @click="reset()">重置</el-button>
            </div>
            <el-button type="primary" size="small" plain style="float:right" @click="exportHis()">导出</el-button>
        </div>
        <el-table
            :data="tableData"
            border
            class="tableStyle"
            height="calc(100vh - 304px)"
          >
            <el-table-column
                label="序号"
                type="index"
                width="52"
            >
            </el-table-column>
            <el-table-column
              v-for="col in head"
              :key="col.prop"
              :prop="col.prop"
              :label="col.label"
              align="left"
              show-overflow-tooltip
              :sortable="col.sorts"
              :width="col.widths"
            >
                <template v-if="col.isBar" #default="{ row }">
                  <div
                    v-if="col.prop == 'remarks'"
                    class="linkBtn"
                  >
                    {{ row.remarks }}
                  </div>
                  <div
                    v-if="col.prop == 'val'"
                  >
                    {{ row.val + row.unit }}
                  </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageParams.current"
          :page-sizes="[30, 50, 100]"
          :page-size="pageParams.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="TablePages"
        >
        </el-pagination>
    </div>
</template>
<script>
import scadaApi from "@/apis/scadaApi";
import { exportMethod } from '@/common/js/exportExcel'
export default {
    name: 'historyMonitor',
    components: {},
    data() {
        return {
            time:[],
            input3:'',
            select:'',
            pageParams:{
                current:1,
                size: 100,
                startTime:'',
                endTime:"",
                dataType:"",
                queryType:"1",
                queryWord:""
            },
            tableData:[],
            head:[
                { prop: 'equipmentName', label: '设备名称' },
                { prop: 'description', label: '所在位置' },
                { prop: 'monitorPointName', label: '监测点位'},
                { prop: 'val', label: '远传数据',isBar:true },
                { prop: 'dataType', label: '数据类型' },
                { prop: 'warningDescribe', label: '异常信息' },
                { prop: 'uploadTime', label: '上报时间' },
                { prop: 'collectTime', label: '采集时间' }   
            ],
            total:0,
            expireTimeOption: this.expireTimeOptionFun(),
            dataTypeList:[]
        }
    },
    mounted(){
        // 默认今天
        let dates = new Date()
        let ymd =
            dates.getFullYear() +
            '-' +
            (dates.getMonth() + 1 < 10 ? '0' + (dates.getMonth() + 1) : dates.getMonth() + 1) +
            '-' +
            (dates.getDate() < 10 ? '0' + dates.getDate() : dates.getDate())
        let endTime = ymd + ' 23:59:59'
        let startTime = ymd + ' 00:00:00'
        this.time = [startTime, endTime]
        // 设备类型
        scadaApi.getOptions({names:"CollectionPointDataType"}).then(res => {
            this.dataTypeList = res.data.CollectionPointDataType
        })
        // 获取列表
        this.getList()
    },
    methods: {
        reset(){
            let dates = new Date()
            let ymd =
                dates.getFullYear() +
                '-' +
                (dates.getMonth() + 1 < 10 ? '0' + (dates.getMonth() + 1) : dates.getMonth() + 1) +
                '-' +
                (dates.getDate() < 10 ? '0' + dates.getDate() : dates.getDate())
            let endTime = ymd + ' 23:59:59'
            let startTime = ymd + ' 00:00:00'
            this.time = [startTime, endTime]
            this.pageParams.current = 1
            this.pageParams.size = 30
            this.pageParams.startTime = ''
            this.pageParams.endTime = ''
            this.pageParams.dataType = ''
            this.pageParams.queryType = '1'
            this.pageParams.queryWord = ''
            this.getList()
        },
        getList(reset){
            if(reset){
                this.pageParams.current = 1
                this.pageParams.size = 30
            }
            let obj = {
                ...this.pageParams
            }
            if(this.time){
                obj.startTime = this.time[0]
                obj.endTime = this.time[1]
            }
            scadaApi.getHistory(obj).then(res => {
                this.tableData = res.data.list
                this.total = res.data.total
            })
        },
        expireTimeOptionFun() {
            let selectData = []
            return {
                onPick: ({ maxDate, minDate }) => {
                    selectData[0] = minDate?.getTime()
                    selectData[1] = maxDate?.getTime()
                },
                disabledDate(time) {
                    //选择时间
                    if (selectData[0] && !selectData[1]) {
                        return (
                            time.getTime() > selectData[0] + 24 * 60 * 60 * 6 * 1000 ||
                            time.getTime() < selectData[0] - 24 * 60 * 60 * 6 * 1000
                        )
                    }
                }
            }
        },
        handleSizeChange(e){
            this.pageParams.size = e
            this.getList()
        },
        handleCurrentChange(e){
            this.pageParams.current = e
            this.getList()
        },
        exportHis(){
           let obj = JSON.parse(JSON.stringify(this.pageParams))
           if(this.time){
                obj.startTime = this.time[0]
                obj.endTime = this.time[1]
            }
           obj.current = 0
           obj.size = -1
           scadaApi.exportScadaHistory(obj).then(res => {
                exportMethod(res,'历史数据')
           }) 
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep.input-with-select .el-input-group__prepend{
    background-color: #fff;
    color: #0F0F0F;
}
.tabs {
  margin: 10px;
  background-color: #fff;
  height: calc(100vh - 206px);
  padding: 16px 10px;
  .topSearch{
        color: #0F0F0F;
        padding: 0px 0px 16px 10px;
        .selectBox{
            width: 200px;
            margin: 0 30px 0 0;
        }
        .searchBtn{
            display: inline-block;
        }
    }
}
.TablePages{
    margin: 16px 0;
    padding-left: 0;
}
</style>